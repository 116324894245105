const defaultNoPlusWords = [
  "a",
  "e",
  "o",
  "com",
  "da",
  "de",
  "do",
  "em",
  "ou",
  "no",
  "para",
  "se",
]

type GeneratorOptions = {
  space: boolean
  quotes: boolean
  brackets: boolean
  plus: boolean
}

function formatListOfKeywords(
  listOfKeywords: Array<string>,
  noPlusWords: Array<string>,
  generatorOptions: GeneratorOptions
): Array<string> {
  return listOfKeywords.flatMap(function (keywords) {
    return formatKeywords(keywords, noPlusWords, generatorOptions)
  })
}

function formatKeywords(
  keywords: string,
  noPlusWords: Array<string>,
  generatorOptions: GeneratorOptions
): Array<string> {
  const trimmedKeywords = keywords.trim().replace(/ +(?= )/g, "") // Remove multiple spaces
  const splitted = trimmedKeywords.split(" ")

  const generated = []

  if (generatorOptions.space) {
    generated.push(trimmedKeywords)
  }

  if (generatorOptions.quotes) {
    generated.push('"' + trimmedKeywords + '"')
  }

  if (generatorOptions.brackets) {
    generated.push("[" + trimmedKeywords + "]")
  }

  if (generatorOptions.plus) {
    const wordsWithPlus = splitted.map(function (word) {
      return prependPlusIfNeeded(word, noPlusWords)
    })
    generated.push(wordsWithPlus.join(" "))
  }

  return generated
}

function prependPlusIfNeeded(word: string, noPlusWords: Array<string>): string {
  return noPlusWords.includes(word) ? word : "+" + word
}

export { formatListOfKeywords, defaultNoPlusWords }
