import * as React from "react"
import { Helmet } from "react-helmet"
import {
  formatListOfKeywords,
  defaultNoPlusWords,
} from "../services/keywordService"
import BodyClassName from "react-body-classname"

const KeywordsPage: React.FC = () => {
  const [output, setOutput] = React.useState("")
  const [showToast, setShowToast] = React.useState(false)

  // Configuration
  const [configurationOpen, setConfigurationOpen] = React.useState(true)
  const [noPlusText, setNoPlusText] = React.useState(
    defaultNoPlusWords.join(", ")
  )
  const [generatorOptions, setGeneratorOptions] = React.useState({
    space: true,
    quotes: true,
    brackets: true,
    plus: true,
  })

  function configurationOnClick() {
    setConfigurationOpen(!configurationOpen)
  }

  function noPlusOnInput(event: React.FormEvent<HTMLTextAreaElement>) {
    const inputValue = event.target.value
    setNoPlusText(inputValue)
  }

  // Refactor this, use a checkbox component
  function spaceOnChange() {
    setGeneratorOptions({
      ...generatorOptions,
      quotes: !generatorOptions.space,
    })
  }

  function quoteOnChange() {
    setGeneratorOptions({
      ...generatorOptions,
      quotes: !generatorOptions.quotes,
    })
  }

  function bracketsOnChange() {
    setGeneratorOptions({
      ...generatorOptions,
      brackets: !generatorOptions.brackets,
    })
  }

  function plusOnChange() {
    setGeneratorOptions({
      ...generatorOptions,
      plus: !generatorOptions.plus,
    })
  }

  // Textareas
  function onInput(event: React.FormEvent<HTMLTextAreaElement>) {
    const inputValue = event.target.value.trim()
    if (inputValue == "") {
      setOutput("")
      return
    }

    const listOfKeywords = inputValue
      .split("\n")
      .filter((str: string) => str.trim().length > 0)
    const noPlusWords = noPlusText.split(",").map((str) => str.trim())
    const formattedListOfKeywords = formatListOfKeywords(
      listOfKeywords,
      noPlusWords,
      generatorOptions
    )
    const formattedOutput = formattedListOfKeywords.join("\n")
    setOutput(formattedOutput)
  }

  function onClickCopy() {
    navigator.clipboard.writeText(output)
    setShowToast(true)
    setTimeout(() => {
      setShowToast(false)
    }, 750)
  }

  return (
    <>
      <BodyClassName className="bg-yellow-200">
        <main className="flex h-screen">
          <Helmet>
            <title>Keywords | Publitools</title>
          </Helmet>

          <div className="container w-full h-3/4 m-auto flex flex-col">
            <div className="bg-white rounded-lg px-5 py-4">
              <div
                className="flex flex-row justify-between items-center cursor-pointer"
                onClick={configurationOnClick}
              >
                <h3 className="text-xl font-bold">Configurações</h3>
                <span className="text-2xl">
                  {configurationOpen ? "▼" : "▶"}
                </span>
              </div>
              <div
                className={`flex flex-row mt-4 ${
                  configurationOpen ? "" : "hidden"
                }`}
              >
                <div className="flex-1">
                  <h3 className="text-lg font-medium">
                    Palavras sem &quot;+&quot;
                  </h3>
                  <div className="flex">
                    <textarea
                      className="resize-none border rounded-lg w-full p-3"
                      onInput={noPlusOnInput}
                      value={noPlusText}
                    ></textarea>
                  </div>
                </div>
                <div className="flex-1 ml-8">
                  <h3 className="text-lf font-medium">Gerar</h3>
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        checked={generatorOptions.space}
                        onChange={spaceOnChange}
                      />{" "}
                      Palavras separadas por espaço{" "}
                      <small className="px-2 py-1 rounded-full bg-indigo-500 text-white">
                        Novo
                      </small>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        checked={generatorOptions.quotes}
                        onChange={quoteOnChange}
                      />{" "}
                      &quot;Palavras entre aspas&quot;
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        checked={generatorOptions.brackets}
                        onChange={bracketsOnChange}
                      />{" "}
                      [Palavras entre chaves]
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        checked={generatorOptions.plus}
                        onChange={plusOnChange}
                      />{" "}
                      +Palavras +com +mais
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-1 mt-8">
              <div className="flex-1">
                <textarea
                  className="w-full h-full rounded-lg p-5 resize-none"
                  placeholder="Escreva uma keyword por linha..."
                  onInput={onInput}
                ></textarea>
              </div>

              <div className="flex-1 ml-8 relative">
                <textarea
                  className="w-full h-full rounded-lg p-5 resize-none"
                  value={output}
                  readOnly={true}
                ></textarea>
                <button
                  className={`absolute top-3 right-3 ${
                    showToast
                      ? "bg-indigo-300 cursor-default"
                      : "bg-indigo-500 cursor-pointer"
                  } px-8 py-3 rounded-lg font-bold text-white`}
                  disabled={showToast}
                  onClick={onClickCopy}
                >
                  {showToast ? "Copiado!" : "Copiar"}
                </button>
              </div>
            </div>
          </div>
        </main>
      </BodyClassName>
    </>
  )
}

export default KeywordsPage
